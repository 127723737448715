import { Grid } from '@mui/material';

import { Modal } from '../../components/NewModal/styles';

import { DropzoneCustom } from '../../components/DropzoneCustom';
import ExcelRenderer from '../../utils/excelRenderer';
import theme from '../../theme';
import Button from '../../components/Button';
import SearchableTable from '../../components/Interface/SearchableTable';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Heading2 } from '../../components/Interface/Text';
import ContractsService from '../../services/administrator/contracts.service';
import { TOAST_MESSAGES } from '../../helpers/toastMessages';
import Alerts from '../../components/Alerts';

const statusOptions = {
  ativo: 'active',
  cancelado: 'cancelled',
  'aguardando cancelamento': 'pending_cancel',
  'aguardando transferência': 'pending_transfer',
  'aguardando ativação': 'pending_activation',
  'aguardando de assinatura': 'pending_signature',
  rascunho: 'draft',
};

const ModalUpdateMassiveStatus = ({ onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [standardTableColumns, setStandardTableColumns] = useState([]);
  const [contractsToUpdate, setContractsToUpdate] = useState([]);

  const mapErrors = {
    columnContract: 'A primeira coluna deve-se chamar "Contratos".',
    columnStatus: 'A segunda coluna deve-se chamar "Status".',
    invalidStatus: 'A planilha apresenta status inválidos.',
    invalidType: "O formato do arquivo deve ser 'xlsx'",
  };

  const defaultError =
    'Problemas no carregamento da planilha. Tente novamente.';

  const normalizeStatus = (status) => {
    return status
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  };

  const getStatus = (status) => {
    const normalizedStatus = normalizeStatus(status);
    const key = Object.keys(statusOptions).find(
      (key) => normalizeStatus(key) === normalizedStatus
    );
    return key ? statusOptions[key] : null;
  };

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      const { fails, success } = await ContractsService.updateMassiveStatus(
        contractsToUpdate
      );
      toast.info(
        `${success} ${
          success !== 1 ? 'contratos atualizados' : 'contrato atualizado'
        } com sucesso${
          fails > 0
            ? ` e ${fails} ${
                fails !== 1
                  ? 'contratos não foram atualizados'
                  : 'contrato não foi atualizado'
              }.`
            : '.'
        }`
      );
      onClose(false);
    } catch (error) {
      toast.error(TOAST_MESSAGES.ERROR);
    } finally {
      setIsSubmitting(false);
    }
  };

  const dataHandler = (data) => {
    const arrayOfArrays = data.rows;
    const [headers, ...rows] = arrayOfArrays;

    const regexHeader = /contratos?/gi;
    if (!headers[0].match(regexHeader)) throw mapErrors.columnContract;

    const regexStatusHeader = /status?/gi;
    if (!headers[1].match(regexStatusHeader)) throw mapErrors.columnStatus;

    try {
      const assembleArrayOfColumns = headers.slice(0, 2).map((el) => {
        return { name: el };
      });
      const filteredRows = rows
        .map((row) => row.slice(0, 2))
        .filter((el) => el.length > 0);

      const contractsToUpdate = filteredRows.map((row) => {
        const status = getStatus(row[1]);
        if (!status) throw mapErrors.invalidStatus;
        return {
          contractId: row[0],
          status,
        };
      });

      setRows(filteredRows);
      setColumns(headers);
      setStandardTableColumns(assembleArrayOfColumns);
      setContractsToUpdate(contractsToUpdate);
    } catch (error) {
      throw error;
    }
  };

  const fileHandler = async (files) => {
    try {
      const fileObj = files[0];
      const { type } = fileObj;
      if (
        type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
        throw mapErrors.invalidType;

      const data = await ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
          throw err;
        } else {
          return resp;
        }
      });
      dataHandler(data);
    } catch (error) {
      Object.values(mapErrors).includes(error.toString())
        ? toast.error(error)
        : toast.error(defaultError);
    }
  };

  const getRows = (rows) => {
    try {
      return rows.map((row) => {
        const arr = row.map((cell) => {
          return { value: <p>{cell}</p> };
        });

        return { values: arr };
      });
    } catch (error) {
      throw error;
    }
  };

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      width='800px'
    >
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        spacing={1}
        style={{ padding: '10px 20px' }}
      >
        <Grid
          item
          xs={12}
        >
          <Heading2 align='center'>
            Alterar status de contratos em lote
          </Heading2>
          <Alerts
            alerts={[
              {
                type: 'info',
                message: `Status permitidos para contratos: ${Object.keys(
                  statusOptions
                ).join(', ')}.`,
              },
            ]}
          />
        </Grid>
        <Grid
          item
          justifyContent='center'
          alignItems='center'
          xs={12}
          md={rows.length ? 6 : 12}
        >
          <DropzoneCustom
            maxFiles={1}
            fileHandler={fileHandler}
            acceptedFileTypes={['.xlsx']}
            handlerFileAfterChange={true}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          justifyContent='center'
          alignItems='center'
        >
          {rows.length > 0 && standardTableColumns.length > 0 && (
            <SearchableTable
              columns={standardTableColumns}
              rowsValues={getRows(rows)}
              noItemsText='Nenhum valor encontrado'
              noSearch
              noDownload={true}
              maxHeight='80vh'
            />
          )}
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        justifyContent='space-around'
        style={{ margin: '10px 0' }}
      >
        <Button
          text='Cancelar'
          handleClick={onClose}
          color='white'
          borderColor={theme.palette.primary.main}
          outlined
        />
        <Button
          handleClick={() =>
            onSubmit(
              { promocodes: rows.map((row) => row[0]) },
              { setSubmitting: setIsSubmitting }
            )
          }
          disabled={isSubmitting || rows.length === 0}
          textColor={theme.palette.primary.main}
          color={theme.palette.secondary.main}
          text='Alterar'
          fontSize='50'
          loading={isSubmitting}
        />
      </Grid>
    </Modal>
  );
};

export default ModalUpdateMassiveStatus;
