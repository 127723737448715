import { Grid, Button as ButtonUI, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, {
  useState,
  useEffect,
  createRef,
  useRef,
  useContext,
} from 'react';
import { IoMdSearch } from 'react-icons/io';
import styled from 'styled-components';
import TableContainer from '@mui/material/TableContainer';
import CircularProgress from '@mui/material/CircularProgress';
import * as XLSX from 'xlsx';

import TableChartIcon from '@mui/icons-material/TableChart';
import FlagIcon from '@mui/icons-material/Flag';
import { toast } from 'react-toastify';
import Button from '../../Button';
import { Input } from '../Input';
import {
  Table,
  TableBody,
  TableColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table';
import { Paragraph } from '../Text/Paragraph';
import { isAdmin } from '../../../helpers/userRoles';
import { useAuthStore } from '../../../store';
import { CONTRACT_STATUS_TYPES_PTBR } from '../../../helpers/contractStatusTypes';
import GradientButton from '../../GradientButton';
import { ThemeContext } from '../../../contexts/themeContext';
import { getStatusText } from '../../ContractStatusLabel';
import theme from '../../../theme';
import FlexRowContainer from '../../../componentsStyle/FlexRowContainer';

const CELL_TYPE = {
  text: 's',
  bollean: 'b',
  number: 'n',
  date: 'd',
};

const getValueToFind = (value, userRole) => {
  if (typeof value === 'string') return value;

  if (value?.props?.contract)
    return getStatusText(value?.props?.contract, userRole);

  const valueToCheck = value?.props?.children;
  if (typeof valueToCheck === 'string' || typeof valueToCheck === 'number')
    return valueToCheck.toString();

  if (valueToCheck?.length) {
    const values = valueToCheck;

    for (const value of values) {
      const valueToCheck = value.props?.children;
      if (typeof valueToCheck === 'string') return valueToCheck;
      if (typeof valueToCheck?.props?.children === 'string')
        return valueToCheck?.props?.children;
      if (valueToCheck?.props?.children) return valueToCheck?.props?.children;
      if (valueToCheck?.type === 'textarea')
        return valueToCheck?.props?.children;
    }

    return value.props.children[1];
  }

  if (typeof value?.props?.children !== 'undefined') {
    if (value?.props?.children) {
      if (value?.props?.children?.props) {
        return value?.props?.children?.props?.children;
      }
      if (typeof value?.props?.children[0]?.props !== 'undefined') {
        return value?.props?.children[0].props?.children;
      }
    }
  }
  if (typeof valueToCheck === 'function') {
    return false;
  }
};

const BaseSearchableTable = (
  {
    className,
    columns,
    rowsValues,
    buttonText = undefined,
    buttonOnClick = undefined,
    noSearch = undefined,
    noDownload = undefined,
    noItemsText = undefined,
    buttonBug,
    fetchAll,
    rerender = undefined,
    dataIsComplete = false,
    noTable,
    maxHeight = '80vh',
  },
  children
) => {
  const { themeValue } = useContext(ThemeContext);
  const tableColumnRef = createRef();
  const [filteredRowValues, setFilteredRowValues] = useState([]);
  const role = useAuthStore((state) => state.userAuth?.role);

  const [exportDataset, setExportDataset] = useState(null);
  const [isExportingOrSeaching, setIsExportingOrSeaching] = useState(false);

  const inputRef = useRef(null);

  const onSearch = async () => {
    const valuee = inputRef.current.value;
    try {
      setIsExportingOrSeaching(true);
      const newRowsValues =
        fetchAll && !dataIsComplete ? await fetchAll(true) : rowsValues;
      if (!fetchAll && !dataIsComplete)
        toast.error('Não foi possível pesquisar na base inteira');
      const matchedRows = newRowsValues.filter(({ values }) =>
        values.some(({ value }) => {
          try {
            const valueToFind = getValueToFind(value, role);
            if (!valueToFind) return false;

            return (
              valueToFind &&
              valueToFind?.toLowerCase()?.includes(valuee?.toLowerCase())
            );
          } catch (error) {
            console.log('file: index.js || line 127 || error', error);
            console.log('file: index.js || line 127 || value', value);
          }
        })
      );
      setFilteredRowValues(matchedRows);
    } catch (err) {
      console.log(err);
    } finally {
      setIsExportingOrSeaching(false);
    }
  };

  const defFormat = (value) => {
    switch (typeof value.props?.children) {
      case 'string':
        if (value.props.children.startsWith('2021-')) {
          return value.props.children.split('T')[0].replaceAll('-', '/');
        } else {
          return value;
        }
      case 'number':
        return value.props.children;

      case 'object':
        if (value?.props?.children?.length === 2) {
          if (value?.props?.children?.[0]?.type === 'textarea') {
            return (
              <NotesForm>
                <textarea
                  rows='3'
                  type='text'
                  name='notes'
                  value={value?.props?.children?.[0]?.props?.children}
                >
                  {value?.props?.children?.[0]?.props?.children}
                </textarea>
                <button type='submit'>Salvar</button>
              </NotesForm>
            );
          } else if (value?.props?.children?.[0]?.type === 'p') {
            if (value?.props?.children?.[0]?.props?.children === 'noflag') {
              return (
                <>
                  <p hidden>noflag</p>
                  <p hidden>noflag</p>
                </>
              );
            } else if (
              value?.props?.children?.[0]?.props?.children === 'flag'
            ) {
              return (
                <>
                  <FlagIcon style={{ color: 'red' }} />
                  <p
                    value='flag'
                    hidden
                  >
                    flag*
                  </p>
                </>
              );
            } else {
              return value;
            }
          }
        } else {
          return value;
        }

      default:
        return value;
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (inputRef.current?.value && !isExportingOrSeaching) {
        onSearch();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    setFilteredRowValues(rowsValues);
  }, [rowsValues?.length, rerender]);

  const handleMouseEnter = () => {
    if (buttonBug) {
      tableColumnRef.current.firstChild.firstChild.firstChild.firstChild.firstChild.style.backgroundColor =
        '#ececec';
    }
  };

  const handleMouseLeave = () => {
    if (buttonBug) {
      tableColumnRef.current.firstChild.firstChild.firstChild.firstChild.firstChild.style.backgroundColor =
        'white';
    }
  };

  const fixException = (value) => {
    const isDocument = value?.split('.')?.length > 2;
    if (isDocument) return value;

    const isEmail = value.includes('@') && value.includes('.');
    if (isEmail) return value;

    return value
      .replace('MWh', '')
      .replace('kWh', '')
      .replaceAll('R$', '')
      .replace('.', '')
      .replace(',', '.')
      .replace('-', '')
      .trimEnd();
  };

  const fixNumber = (value) => parseFloat(value.replace('-', ''));

  const handleDefault = (value, columnName) => {
    try {
      if (columnName === 'Código Iugu') return value;
      if (typeof value === 'number') return value;
      if (value == null) return;
      if (value === ' ') return ' ';

      const fixedValue = fixException(value);
      if (fixedValue === '') return;

      return Number.isNaN(+fixedValue) ? fixedValue : fixNumber(fixedValue);
    } catch (error) {
      console.log('file: index.js || line 232 || error', error);
      throw error;
    }
  };

  const fixInitialValue = (values) => {
    if (!(values instanceof Array)) return values;
    if (values == null) return;

    const objectToCheck = {};
    values.map((value) => {
      const children = value?.props?.value || value?.props?.children;
      const valueToTest = children || value;
      if (valueToTest === '#') return;
      const type = typeof valueToTest;
      if (!objectToCheck[type]) objectToCheck[type] = [valueToTest];
      else objectToCheck[type].push(valueToTest);
    });

    if (objectToCheck.string) return objectToCheck.string.join(' ');

    return fixInitialValue(values[0]?.props?.children);
  };

  const getRowValue = (rowObject, column) => {
    let preTargetValue;
    let contract;
    if (rowObject && typeof rowObject === 'object' && 'props' in rowObject) {
      ({ children: preTargetValue, contract } = rowObject.props);
    } else {
      preTargetValue = rowObject;
    }

    let finalValue;

    if (preTargetValue == null && !contract) return;
    const { name: columnName } = column;
    if (columnName.includes('%')) {
      finalValue = fixInitialValue(preTargetValue.replace('%', ''));

      const value = handleDefault(finalValue, columnName);
      if (!value) return;
      return value / 100;
    }

    switch (columnName) {
      case 'Status':
        if (contract) return getStatusText(contract, 'admin');
        return CONTRACT_STATUS_TYPES_PTBR?.[preTargetValue] || preTargetValue;
      default:
        finalValue = fixInitialValue(preTargetValue);
        return handleDefault(finalValue, columnName);
    }
  };

  const handleDataExport = (data, headers) => {
    let rows = data.map(({ values }) =>
      values.map(({ value }, index) => getRowValue(value, headers[index]))
    );

    if (!rows.length) rows = [Array(headers.length).fill(null)];

    const multiDataSet = [{ columns: headers, data: rows }];

    setExportDataset(multiDataSet);
  };

  const exportData = async () => {
    try {
      setIsExportingOrSeaching(true);
      const headers = columns.map(({ name, type }) => {
        if (name.includes('%'))
          toast.warning(
            `A coluna ${name} está sendo baixada como número decimal`
          );
        return { name, type };
      });
      if (fetchAll && !dataIsComplete) {
        const data = await fetchAll();
        return handleDataExport(data, headers);
      }
      if (!dataIsComplete)
        toast.error('Não foi possível extrair a base inteira');
      return handleDataExport(rowsValues, headers);
    } catch (error) {
      console.log('file: index.js || line 320 || error', error);
      toast.error('Houve um erro desconhecido');
      setIsExportingOrSeaching(false);
    }
  };

  useEffect(() => {
    if (!exportDataset) return;
    // if (exportExcelRef?.current) exportExcelRef.current.click();
    setIsExportingOrSeaching(false);

    const exportToExcel = async () => {
      const fileExtension = '.xlsx';
      const fileName = 'Download';
      const { columns, data } = exportDataset[0];

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet([], { dense: true });
      XLSX.utils.sheet_add_aoa(ws, [columns.map((column) => column.name)]);
      // Starting in the second row to avoid overriding and skipping headers
      XLSX.utils.sheet_add_json(ws, data, {
        origin: 'A2',
        skipHeader: true,
      });

      const columnsSpecificType = columns.filter((column) => !!column.type);

      if (columnsSpecificType?.length > 0) {
        for (const column of columnsSpecificType) {
          //busca o indice da coluna
          const columnIndex = columns.findIndex(
            (item) => item.name === column.name
          );
          if (columnIndex !== -1) {
            // Iterar sobre os dados e definir o tipo de dados para a coluna desejada
            data.forEach((row, rowIndex) => {
              ws[XLSX.utils.encode_cell({ c: columnIndex, r: rowIndex })].t =
                CELL_TYPE[column.type];
            });
          }
        }
      }

      XLSX.utils.book_append_sheet(wb, ws, 'Dados');
      XLSX.writeFile(wb, `${fileName}${fileExtension}`);

      setExportDataset();
    };

    exportToExcel();
  }, [exportDataset]);

  return (
    <div className={className}>
      {buttonOnClick && (
        <Grid
          container
          justify='flex-end'
          className='actions-wrapper'
        >
          <Grid item>
            <Button
              text={buttonText}
              outlined
              rectangle
              handleClick={buttonOnClick}
              color='primary'
            />
          </Grid>
        </Grid>
      )}
      <div>
        <FlexRowContainer
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            flexGrow: 1,
            marginBottom: 5,
          }}
        >
          {!(noTable || noSearch) && (
            <SearchDiv>
              <Input
                placeholder='Pesquisar'
                // onChange={onSearch}
                ref={inputRef}
                icon={IoMdSearch}
              />
              <GradientButton
                handleClick={onSearch}
                text='Search'
                paddingg='5px 10px'
                background={theme.palette.secondary.main}
                color={theme.palette.primary.main}
              />
            </SearchDiv>
          )}
          {isAdmin(role) && !noDownload && (
            <ExportDiv>
              <ExportButton
                variant='contained'
                onClick={() => exportData()}
                disableElevation
              >
                {!isExportingOrSeaching ? (
                  <Typography>Download All</Typography>
                ) : (
                  <CircularProgress
                    style={{ color: theme.palette.primary.main }}
                  />
                )}
              </ExportButton>
            </ExportDiv>
          )}
        </FlexRowContainer>

        {!noTable && (
          <TableContainer style={{ maxHeight, overflowY: 'auto' }}>
            <Table>
              <TableHeader color={themeValue?.text_color}>
                {columns.map(({ name, align }) => (
                  <TableHeaderColumn
                    key={name}
                    align={align}
                  >
                    {name}
                  </TableHeaderColumn>
                ))}
              </TableHeader>
              <TableBody>
                {filteredRowValues.length > 0 &&
                  filteredRowValues.map(({ values, active }, rowIndex) => {
                    return (
                      <TableRow
                        key={rowIndex.toString()}
                        active={active}
                        onMouseEnter={() => handleMouseEnter()}
                        onMouseLeave={() => handleMouseLeave()}
                      >
                        {values.map(({ value, align }, valueIndex) => (
                          <TableColumn
                            color={themeValue?.text_color}
                            key={valueIndex.toString()}
                            align={align}
                            ref={tableColumnRef}
                          >
                            {defFormat(value)}
                          </TableColumn>
                        ))}
                      </TableRow>
                    );
                  })}
                {filteredRowValues.length === 0 && (
                  <TableRow>
                    <TableColumn colSpan={columns.length}>
                      <Paragraph
                        align='center'
                        style={{ color: themeValue?.text_color }}
                      >
                        {noItemsText || 'Nenhum item listado'}
                      </Paragraph>
                    </TableColumn>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

BaseSearchableTable.propTypes = {
  className: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      align: PropTypes.oneOf(['left', 'right', 'center', 'justify', 'char']),
    })
  ).isRequired,
  rowsValues: PropTypes.arrayOf(
    PropTypes.shape({
      values: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.any,
          align: PropTypes.oneOf([
            'left',
            'right',
            'center',
            'justify',
            'char',
          ]),
        })
      ),
      active: PropTypes.bool,
    })
  ).isRequired,
  buttonText: PropTypes.string,
  buttonOnClick: PropTypes.func,
  noSearch: PropTypes.bool,
  noDownload: PropTypes.bool,
  noItemsText: PropTypes.string,
  maxHeight: PropTypes.number,
  rerender: PropTypes.bool,
  dataIsComplete: PropTypes.bool,
};

const NotesForm = styled.form`
  display: flex;
  align-items: center;
`;

const SearchableTable = styled(BaseSearchableTable)`
  max-height: ${(props) => (props.maxHeight ? `${props.maxHeight}px` : 'none')};
  overflow: ${(props) => (props.maxHeight ? 'auto' : 'initial')};
  margin-bottom: 10px;
  .actions-wrapper {
    padding-right: 20px;
  }
`;

export default SearchableTable;

const ExportButton = styled(ButtonUI)(() => ({
  color: `${theme.palette.primary.main} !important `,
  background: `${theme.palette.secondary.main} !important `,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
  ' > svg': {
    color: `${theme.palette.primary.main} !important `,
  },
}));

const ExportDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  height: fit-content;
  align-items: center;
  background-color: none;
  .MuiSvgIcon-root {
    color: white;
    border: none;
    height: 30px;
    width: auto;
    margin-left: 5px;
  }
`;

const SearchDiv = styled.div`
  display: flex;
  width: 25vw;
  justify-content: space-between;
`;
