const taxFields = {
  icms: ['DGV_ICMS_BASE_AUX', 'DGV_STPRZ_AUX', 'DGV_IICMS_AUX'],
  pasep: ['DGV_BASE_PASEP', 'DGV_PERC_PASEP', 'DGV_PASEP_VALOR'],
  cofins: ['DGV_BASE_COF', 'DGV_PERC_COF', 'DGV_COFINS_VALOR'],
};

const consumptionFieldsPrefix = ['DGV_CONSUMO_', 'DGV_CONSTANTE_'];

const getConsumeColumns = (maxSize = 8) => {
  const columns = [];
  for (let i = 1; i <= maxSize; i += 1)
    for (const prefix of consumptionFieldsPrefix)
      columns.push(prefix + i.toString().padStart(2, '0'));
  return columns;
};

const taxFieldsArray = Object.values(taxFields).flat();

const desiredColumnsVA = [
  'DGV_FAT_001',
  'DGV_FAT_002',
  'DGV_CLIENTE',
  'DGV_INSTALACAO',
  'DGV_MES_CONTA',
  'DGV_DT_VENC',
  'DGV_TOTAL_FINAL',
  'DGV_CLASS',
  'DGV_SUBCLASSE1',
  'DGV_DT_LEITURA_ATUAL',
  'DGV_DT_LEITURA_ANT',
  'DGV_PROX_LEITURA',
  'DGV_EMISSAO',
  ...getConsumeColumns(),
  'DGV_TEXTO_ITEM',
  'DGV_QUANT',
  'DGV_PRECO',
  'DGV_VALOR',
  ...taxFieldsArray,
];

const desiredColumnsBalanceLight = [
  'Instalação',
  'Período',
  'Posto Horário (desc.)',
  'Saldo Anterior',
  'Consumo',
  'Geração',
  'Compensação',
  'Transferência',
  'Recebimento',
  'Saldo Atual',
  'Saldo a Expirar (qtd)',
  'Data de vencimento',
];

const desiredColumnsBalanceLightMap = {
  Instalação: 'instalacao',
  Período: 'periodo',
  'Posto Horário (desc.)': 'posto_horario',
  'Saldo Anterior': 'saldo_anterior',
  Consumo: 'consumo',
  Geração: 'geracao',
  Compensação: 'compensacao',
  Transferência: 'transferencia',
  Recebimento: 'recebimento',
  'Saldo Atual': 'saldo_atual',
  'Saldo a Expirar (qtd)': 'saldo_expirar',
  'Data de vencimento': 'data_vencimento',
};

const validationDate = (dateString, messageErrors) => {
  if (!dateString)
    throw new Error(messageErrors.no_data || 'Campo data deve ser informado');

  const dateSplit = dateString.split('/');

  if (dateSplit.length !== 3) {
    throw new Error(
      messageErrors.incorrect_format || 'Formato de data incorreto'
    );
  }

  const date = new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0]);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (
    !(
      date instanceof Date &&
      !isNaN(date) &&
      date.getDate() == dateSplit[0] &&
      date.getMonth() == dateSplit[1] - 1 &&
      date.getFullYear() == dateSplit[2]
    )
  )
    throw new Error(
      messageErrors.incorrect_format || 'Data no formato incorreto'
    );
  else if (date < today)
    throw new Error(
      messageErrors.lower_than_today || 'Data inferior ao dia atual'
    );
};

const adjustmentExcelDate = (excelDateSerialNumber, messageErrors) => {
  if (isNaN(excelDateSerialNumber))
    throw new Error(
      messageErrors.incorrect_format || 'Campo data com formato incorreto'
    );
  const excelStartDate = new Date(1904, 0, 1);
  const startDate = new Date(1899, 11, 30); // Ponto de partida padrão do Excel

  const milliseconds = excelDateSerialNumber * 24 * 60 * 60 * 1000;

  let date;

  if (milliseconds > 0) {
    date = new Date(startDate.getTime() + milliseconds);
  } else {
    date = new Date(excelStartDate.getTime() + milliseconds);
  }

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return (
    (day < 10 ? '0' : '') +
    day +
    '/' +
    (month < 10 ? '0' : '') +
    month +
    '/' +
    year
  );
};

export {
  desiredColumnsVA,
  desiredColumnsBalanceLight,
  desiredColumnsBalanceLightMap,
  validationDate,
  adjustmentExcelDate,
};
